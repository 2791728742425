/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react'
import { navigate } from 'gatsby'

import SEO from '../components/SEO'
import AppContext from '../components/AppContext'

import useForm from '../utils/useForm'
import useUser from '../utils/useUser'
import { removeRedirect } from '../utils/setRedirect'

function ErrorMsg({ message }) {
  return <div className="error-message">{message}</div>
}

export default function Register() {
  const { values, updateValue } = useForm({
    email: '',
    pass: '',
    mappleSyrup: '',
  })
  const { loginUser, error, loading, message } = useUser({
    values,
  })

  const { user } = useContext(AppContext)
  const [loggedUser] = user

  if (loggedUser && typeof window !== 'undefined') {
    const redirect = localStorage.getItem(`redirect-url`)
    removeRedirect()
    navigate(redirect || '/')
  }

  return (
    <>
      <SEO title="Iniciar sesión" />
      <main className="bg-gray-200 h-screen flex items-center justify-center">
        <form onSubmit={loginUser} className="LoginForm bg-white">
          <h2 className="bg-pxblue-700 text-white border-b-4 border-pxred py-2 px-4 text-xl font-medium tracking-wide select-none">
            PXTepremia
          </h2>
          <fieldset disabled={loading}>
            <input
              type="text"
              name="mappleSyrup"
              id="mappleSyrup"
              value={values.mappleSyrup}
              onChange={updateValue}
              className="mappleSyrup"
            />
            <div className="w-full mb-4">
              <label
                htmlFor="email"
                className="block text-pxtx-200 font-medium mb-1 select-none"
              >
                E-mail
              </label>
              <input
                id="email"
                type="email"
                name="email"
                value={values.user}
                onChange={updateValue}
                className="w-full rounded-md p-2 border border-pxblue-700 bg-gray-50 text-pxblue-500 font-medium focus:ring-2 focus:ring-pxblue-400"
              />
            </div>
            <div className="w-full mb-4">
              <label
                htmlFor="pass"
                className="block text-pxtx-200 font-medium mb-1 select-none"
              >
                ID
              </label>
              <input
                id="pass"
                name="pass"
                type="password"
                value={values.pass}
                onChange={updateValue}
                className="w-full rounded-md p-2 border border-pxblue-700 bg-gray-50 text-pxblue-500 font-medium focus:ring-2 focus:ring-pxblue-400"
              />
            </div>
            <div className="flex justify-center mb-4">
              <button
                type="submit"
                className="LoginButton focus:ring-4 focus:ring-pxblue-400"
                disabled={loading}
              >
                {loading ? 'Iniciando Sesión' : 'Acceder'}
                {loading ? <span className="spinner" /> : null}
              </button>
            </div>
          </fieldset>
          {error ? <ErrorMsg message={message} /> : null}
        </form>
      </main>
    </>
  )
}
