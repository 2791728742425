import { useState, useContext } from 'react'
import AppContext from '../components/AppContext'

import { setLoggedToken } from './setToken'

export default function useUser({ values }) {
  const { user } = useContext(AppContext)
  const setLoggedUser = user[1]
  const [error, setError] = useState()
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')

  async function loginUser(e) {
    e.preventDefault()
    const body = {
      email: values.email,
      pass: values.pass,
      mappleSyrup: values.mappleSyrup,
    }
    let req = null
    let res = null

    try {
      setLoading(true)
      req = await fetch(
        `${process.env.GATSBY_SERVERLESS_BASE}/functions/user-login/loginUser`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        }
      )
      res = JSON.parse(await req.text())
    } catch (err) {
      res = { message: 'err' }
    }

    if (req.status >= 400 && req.status < 600) {
      setLoading(false)
      setMessage(res.message)
      setError(true)
    } else {
      setLoading(false)
      setMessage('')
      setError(false)
      setSuccess(true)
      setLoggedToken(res.token, 'user')
      setLoggedUser(true)
    }
  }

  return {
    loginUser,
    success,
    error,
    loading,
    message,
  }
}
